const features = ({
  products,
  campaigns,
  accounts,
  prospects,
  sequences,
  sendSchema,
  dataMigration,
}) => {
  return [
    {
      name: 'Products',
      items: [
        {
          name: `${products} products`,
          tooltip: `Create up to ${products} products and keep track of performance`,
        },
        {
          name: 'Report',
          tooltip:
            'Includes insightful report for each product to keep track of performance',
        },
        {
          name: 'Buyer personas',
          tooltip: 'Create unlimited buyer personas for each product',
        },
        {
          name: 'Ideal customer profiles',
          tooltip: 'Create unlimited ideal customer profiles for each product',
        },
        {
          name: 'Value propositions',
          tooltip:
            'Create unlimited value propositions for each product and use them in your templates',
        },
      ],
    },
    {
      name: 'Campaigns',
      items: [
        {
          name: `${campaigns} campaigns`,
          tooltip: `Create ${campaigns} campaigns to segment your accounts`,
        },
        {
          name: 'Report',
          tooltip:
            'Includes insightful report for each campaign to keep track of performance',
        },
      ],
    },
    {
      name: 'Sequences',
      items: [
        {
          name: `${sequences} sequences`,
          tooltip: `Create up to ${sequences} different sequences`,
        },
      ],
    },
    {
      name: 'Templates',
      items: [
        {
          name: 'Unlimited templates',
          tooltip:
            'Create unlimited templates that automaticicaly populate different variables for the ultimate personalized message',
        },
      ],
    },
    {
      name: 'Accounts',
      items: [
        {
          name: `${accounts} accounts`,
          tooltip: `Create up to ${accounts} accounts`,
        },
        {
          name: '360 overview',
          tooltip:
            'View buyer personas per campaign related to that account, all contact for that account and all prospect feedback received',
        },
      ],
    },
    {
      name: 'Prospects',
      items: [
        {
          name: `${prospects} prospects`,
          tooltip: `Create up to ${prospects} accounts`,
        },
        {
          name: '360 overview',
          tooltip:
            'All contact details (including multiple email adddresses), manage current sequences and tasks, full overview of all colleagues and add feedback',
        },
      ],
    },
    {
      name: 'Mailbox synchronization',
      items: [
        {
          name: 'Gmail api',
          tooltip:
            'Easily login with a gmail prodiver. The system will send emails automatically and watch for replies or bounces',
        },
        {
          name: 'Office365 api',
          tooltip:
            'Easy login with you office365/outlook account. The system will send emails automatically and watch for replies or bounces',
        },
      ],
    },
    {
      name: 'System configs',
      items: [
        {
          name: 'Data validation',
          tooltip:
            'Create multiple options for each data field to standardize the data input across the platform',
        },
        {
          name: 'Required fields',
          tooltip:
            'Select which fields are required for prospects, accounts, campaigns or products',
        },
        {
          name: 'Stage',
          tooltip: 'Create stages for prospects, accounts or campaigns',
        },
        {
          name: 'Custom fields',
          tooltip:
            'Leverage custom fields for prospects, accounts, campaigns and products and create your own labels',
        },
        {
          name: 'Import data',
          tooltip: 'Easily import data into the system from csv files',
        },
        {
          name: 'Smart send schema',
          tooltip:
            "Create multiple send schema to make sure emails won't get delivered outside office hours",
          boolean: sendSchema,
        },
        {
          name: 'Exclude email domains',
          tooltip:
            "Exclude email domain and make sure reps won't add them to the system",
        },
        {
          name: 'Sequence limitation',
          tooltip: 'Limit the time in between a prospect can be sequenced',
        },
      ],
    },
    {
      name: 'Support services',
      items: [
        {
          name: 'Integrated helpdesk',
          tooltip: 'Helpdesk support directly integrated in the platform',
        },
        {
          name: 'Data migration support',
          tooltip: 'Support for one time data tranfer from other system',
          boolean: dataMigration,
        },
      ],
    },
  ];
};

const featureList = [
  {
    titel: 'STARTUPS',
    list: {
      products: 1,
      campaigns: 5,
      accounts: 500,
      prospects: 1000,
      sequences: 20,
      sendSchema: false,
      dataMigration: false,
    },
  },
  {
    titel: 'GROWTH',
    list: {
      products: 4,
      campaigns: 20,
      accounts: 1000,
      prospects: 2000,
      sequences: 50,
      sendSchema: true,
      dataMigration: false,
    },
  },
  {
    titel: 'CUSTOM',
    list: {
      products: '∞',
      campaigns: '∞',
      accounts: '∞',
      prospects: '∞',
      sequences: '∞',
      sendSchema: true,
      dataMigration: true,
    },
  },
];

export default featureList.map(list => ({
  titel: list.titel,
  list: features(list.list),
}));
