import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import { info } from 'react-icons-kit/icomoon/info';
import PricingTable, {
  PricingHead,
  PricingPrice,
  PricingButton,
  PricingList,
  ListItem,
  PricingButtonWrapper,
  DiscountLabel,
} from './pricing.style';
import Tooltip from 'common/src/components/Tooltip';
import { checkmark } from 'react-icons-kit/icomoon/checkmark';
import { cross } from 'react-icons-kit/icomoon/cross';
import Rocket from 'common/src/assets/image/saas/rocketIcon.svg';
import Growth from 'common/src/assets/image/saas/growth.svg';
import Custom from 'common/src/assets/image/saas/custom.svg';

const icons = {
  Rocket,
  Growth,
  Custom,
};

const PricingSection = ({
  homePage = false,
  sectionWrapper,
  row,
  secTitleWrapper,
  secHeading,
  secText,
  nameStyle,
  descriptionStyle,
  priceStyle,
  priceLabelStyle,
  buttonStyle,
  buttonFillStyle,
  listContentStyle,
  outlineBtnStyle,
  popularTextStyle,
  handleOpenDemoModal,
  discountAmount,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasJson {
        MONTHLY_PRICING_TABLE {
          icon
          name
          price
          priceLabel
          description
          buttonLabel
          url
          freePlan
          listItems {
            content
            checked
            key
          }
          pricelabelExtraUser
        }
        YEARLY_PRICING_TABLE {
          icon
          name
          price
          priceLabel
          description
          buttonLabel
          url
          freePlan
          listItems {
            content
            checked
            key
          }
          pricelabelExtraUser
        }
        featureTooltip {
          mailbox
          sendSchema
          migration
          teams
          onboarding
          support
          import
        }
      }
    }
  `);

  const [state, setState] = useState({
    data: Data.saasJson.MONTHLY_PRICING_TABLE,
    active: true,
  });
  const DataFeatures = Data.saasJson.featureTooltip;
  const data = state.data;
  const activeStatus = state.active;

  const pricingCarouselOptions = {
    type: 'slider',
    perView: 3,
    gap: 30,
    bound: true,
    breakpoints: {
      1199: {
        perView: 2,
        peek: {
          before: 100,
          after: 100,
        },
      },
      990: {
        perView: 1,
        peek: {
          before: 160,
          after: 160,
        },
      },
      767: {
        perView: 1,
        peek: {
          before: 80,
          after: 80,
        },
      },
      575: {
        perView: 1,
        gap: 15,
        peek: {
          before: 20,
          after: 20,
        },
      },
    },
  };

  function handleClickFeatures() {
    if (homePage) {
      window.open('/pricing#features', '_self');
    } else {
      var element = document.getElementById('features');
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  useEffect(() => {
    if (window) {
      var hash = window.location.hash;
      if (hash) {
        hash = hash.slice(1, hash.length);
        var element = document.getElementById(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }
  }, []);

  return (
    <Box {...sectionWrapper} id="pricing_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} />
          <Heading {...secHeading} />
          <PricingButtonWrapper>
            <Button
              title="Monthly Plan"
              className={activeStatus ? 'active-item' : ''}
              onClick={() =>
                setState({
                  data: Data.saasJson.MONTHLY_PRICING_TABLE,
                  active: true,
                })
              }
            />
            <Button
              title={'Annual Plan'}
              className={activeStatus === false ? 'active-item' : ''}
              onClick={() =>
                setState({
                  data: Data.saasJson.YEARLY_PRICING_TABLE,
                  active: false,
                })
              }
            />
            <DiscountLabel>
              <Text content="Save 15%" {...discountAmount} />
            </DiscountLabel>
          </PricingButtonWrapper>
        </Box>
        <Box {...row}>
          <GlideCarousel
            carouselSelector="pricing-carousel"
            options={pricingCarouselOptions}
            controls={false}
          >
            <>
              {data.map((pricingTable, index) => (
                <GlideSlide key={`pricing-table-${index}`}>
                  <PricingTable
                    freePlan={pricingTable.freePlan}
                    className="pricing_table"
                    style={
                      index === 1
                        ? { border: '1px solid #4285f4', background: '#f3f7fe' }
                        : {}
                    }
                  >
                    {index === 1 ? (
                      <Text content="MOST POPULAR" {...popularTextStyle} />
                    ) : (
                      ''
                    )}
                    <PricingHead>
                      <img
                        style={{
                          height: '60px',
                          position: 'Absolute',
                          marginLeft: '-20px',
                        }}
                        src={icons[pricingTable.icon]}
                        alt="test"
                      />
                      <Heading content={pricingTable.name} {...nameStyle} />
                      <Text
                        content={pricingTable.description}
                        {...descriptionStyle}
                      />
                    </PricingHead>
                    <PricingPrice>
                      {activeStatus === false ? (
                        <Text
                          content={pricingTable.price}
                          icon={
                            <Text
                              style={{ display: 'inline-block' }}
                              content="/month"
                              {...priceLabelStyle}
                            />
                          }
                          {...priceStyle}
                        />
                      ) : (
                        <Text content={pricingTable.price} {...priceStyle} />
                      )}

                      <Text
                        content={pricingTable.priceLabel}
                        {...priceLabelStyle}
                      />
                      <Text
                        content={pricingTable.pricelabelExtraUser}
                        {...priceLabelStyle}
                      />
                      {!activeStatus && (
                        <Text content="Billed annually" {...priceLabelStyle} />
                      )}
                    </PricingPrice>
                    <PricingButton>
                      <Button
                        title={pricingTable.buttonLabel}
                        {...buttonFillStyle}
                        onClick={handleOpenDemoModal}
                      />
                    </PricingButton>
                    <PricingList>
                      {pricingTable.listItems.map((item, indexx) => (
                        <ListItem key={`pricing-table-list-${indexx}${item}`}>
                          <div style={{ width: '90%' }}>
                            <Icon
                              icon={item.checked ? checkmark : cross}
                              style={item.checked ? {} : { color: 'red' }}
                              className="price_list_icon"
                              size={13}
                            />
                            <Text
                              content={item.content}
                              {...listContentStyle}
                            />
                          </div>
                          <div
                            style={{
                              width: '10%',
                              textAlign: 'right',
                              paddingLeft: '40px',
                            }}
                          >
                            {item.key && DataFeatures[item.key] ? (
                              <Tooltip
                                message={DataFeatures[item.key]}
                                position="left"
                                bubbleSize={{
                                  width: '12rem',
                                }}
                              >
                                <Icon
                                  style={{
                                    color: '#5e666e',
                                  }}
                                  icon={info}
                                  size={20}
                                />
                              </Tooltip>
                            ) : (
                              ''
                            )}
                          </div>
                        </ListItem>
                      ))}
                      <ListItem
                        key={`pricing-table-list-pricing-${index}`}
                        style={{
                          justifyContent: 'center',
                          paddingTop: '20px',
                        }}
                      >
                        <Button
                          title="All features"
                          className="outlined"
                          variant="outlined"
                          {...outlineBtnStyle}
                          onClick={handleClickFeatures}
                        />
                      </ListItem>
                    </PricingList>
                  </PricingTable>
                </GlideSlide>
              ))}
            </>
          </GlideCarousel>
        </Box>
      </Container>
    </Box>
  );
};

PricingSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  nameStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  priceStyle: PropTypes.object,
  priceLabelStyle: PropTypes.object,
  listContentStyle: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
  popularTextStyle: PropTypes.object,
};

PricingSection.defaultProps = {
  popularTextStyle: {
    fontSize: '14px',
    lineHeight: '1.75',

    style: {
      position: 'absolute',
      top: 0,
      color: '#4285f4',
      fontWeight: 500,
      left: '45%',
    },
    mb: '0',
  },
  discountAmount: {
    fontSize: '14px',
    color: 'rgb(82, 189, 149)',
    mb: 0,
    as: 'span',
    fontWeight: 700,
  },
  outlineBtnStyle: {
    fontSize: '14px',
    fontWeight: '400',
    color: '#2962ff',
    type: 'button',
    colors: 'primary',
    size: 'small',
    minHeight: 'unset',
  },
  sectionWrapper: {
    as: 'section',
    pt: ['140px', '140px', '140px', '140px', '140px'],
    pb: ['20px', '20px', '20px', '80px'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  secTitleWrapper: {
    mb: ['50px', '75px'],
  },
  secText: {
    content: 'PRICING PLAN',
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#5268db',
    mb: '10px',
  },
  secHeading: {
    content: 'Get a free trial',
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '0',
    as: 'h1',
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    pr: '15px',
    pl: '15px',
  },
  nameStyle: {
    fontSize: ['20px', '20px', '22px', '22px', '22px'],
    fontWeight: '500',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    textAlign: 'center',
    mb: '12px',
  },
  descriptionStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  priceStyle: {
    as: 'span',
    display: 'block',
    fontSize: ['36px', '36px', '40px', '40px', '40px'],
    color: 'headingColor',
    textAlign: 'center',
    mb: '5px',
    letterSpacing: '-0.025em',
  },
  priceLabelStyle: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    colors: 'primary',
    width: '222px',
    maxWidth: '100%',
  },
  buttonFillStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    colors: 'primaryWithBg',
    width: '200px',
    maxWidth: '100%',
  },
  listContentStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    mb: '0',
    display: 'contents',
  },
};

export default PricingSection;
