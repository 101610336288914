import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import { info } from 'react-icons-kit/icomoon/info';
import Tooltip from 'common/src/components/Tooltip';

import PricingTable, {
  PricingHead,
  PricingList,
  ListItem,
} from './featureList.style';

import { checkmark } from 'react-icons-kit/icomoon/checkmark';
import { cross } from 'react-icons-kit/icomoon/cross';
import featureList from './helper';

const PricingSection = ({
  sectionWrapper,
  row,
  secTitleWrapper,
  secHeading,
  secText,
  nameStyle,
  listContentStyle,
}) => {
  const pricingCarouselOptions = {
    type: 'slider',
    perView: 3,
    gap: 30,
    bound: true,
    breakpoints: {
      1199: {
        perView: 2,
        peek: {
          before: 100,
          after: 100,
        },
      },
      990: {
        perView: 1,
        peek: {
          before: 160,
          after: 160,
        },
      },
      767: {
        perView: 1,
        peek: {
          before: 80,
          after: 80,
        },
      },
      575: {
        perView: 1,
        gap: 15,
        peek: {
          before: 20,
          after: 20,
        },
      },
    },
  };

  return (
    <Box {...sectionWrapper} id="features">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} />
          <Heading {...secHeading} />
        </Box>
        <Box {...row}>
          <GlideCarousel
            carouselSelector="feature-carousel"
            options={pricingCarouselOptions}
            controls={false}
          >
            {featureList.map((prop, index) => (
              <GlideSlide key={`feature-${index}`}>
                <PricingTable className="feature_table" freePlan={false}>
                  <PricingHead>
                    <Heading content={prop.titel} {...nameStyle} />
                  </PricingHead>
                  <PricingList>
                    {prop.list.map((list, indexx) => (
                      <>
                        <Text content={list.name} />
                        {list.items.map((item, i) => (
                          <ListItem key={`feature-table-list-${i}${item.name}`}>
                            <div style={{ width: '80%' }}>
                              <Icon
                                icon={
                                  item.boolean === false ? cross : checkmark
                                }
                                style={
                                  item.boolean === false ? { color: 'red' } : {}
                                }
                                className="price_list_icon"
                                size={13}
                              />
                              <Text content={item.name} {...listContentStyle} />
                            </div>
                            <div style={{ width: '20%', textAlign: 'right' }}>
                              <Tooltip
                                message={item.tooltip}
                                position="left"
                                bubbleSize={{
                                  width: '12rem',
                                }}
                              >
                                <Icon
                                  style={{
                                    color: '#5e666e',
                                  }}
                                  icon={info}
                                  size={20}
                                />
                              </Tooltip>
                            </div>
                          </ListItem>
                        ))}
                      </>
                    ))}
                  </PricingList>
                </PricingTable>
              </GlideSlide>
            ))}
          </GlideCarousel>
        </Box>
      </Container>
    </Box>
  );
};

PricingSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  nameStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  priceStyle: PropTypes.object,
  priceLabelStyle: PropTypes.object,
  listContentStyle: PropTypes.object,
};

PricingSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['60px', '80px', '80px', '80px', '120px'],
    pb: ['20px', '20px', '20px', '80px'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  secTitleWrapper: {
    mb: ['50px', '75px'],
  },
  secText: {
    content: 'ALL FEATURES',
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#5268db',
    mb: '10px',
  },
  secHeading: {
    content: 'Contact us for a demo!',
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    pr: '15px',
    pl: '15px',
  },
  nameStyle: {
    fontSize: ['20px', '20px', '22px', '22px', '22px'],
    fontWeight: '500',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    textAlign: 'center',
    mb: '12px',
  },
  descriptionStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  priceStyle: {
    as: 'span',
    display: 'block',
    fontSize: ['36px', '36px', '40px', '40px', '40px'],
    color: 'headingColor',
    textAlign: 'center',
    mb: '5px',
    letterSpacing: '-0.025em',
  },
  priceLabelStyle: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    colors: 'primary',
    width: '222px',
    maxWidth: '100%',
  },
  buttonFillStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    colors: 'primaryWithBg',
    width: '200px',
    maxWidth: '100%',
  },
  listContentStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    mb: '0',
    display: 'contents',
  },
};

export default PricingSection;
